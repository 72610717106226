import {
  MenuItemsType,
  DropdownMenuItemType,
  SwapIcon,
  LockIcon,
  SwapFillIcon,
  EarnFillIcon,
  CommunityFilledIcon,
  EarnIcon,
  BackForwardIcon,
  TrophyFillIcon,
  NftIcon,
  NftFillIcon,
  MoreIcon,
  DropdownMenuItems,
  CommunityIcon,
} from '@pancakeswap/uikit'
import { ContextApi } from '@pancakeswap/localization'
import { nftsBaseUrl } from 'views/Nft/market/constants'
import { perpLangMap } from 'utils/getPerpetualLanguageCode'
import { perpTheme } from 'utils/getPerpetualTheme'
import { SUPPORT_ONLY_BSC } from 'config/constants/supportChains'
import { SubSwapType, SubSwapName } from '@pancakeswap/sdk'

import { LogoSuffix } from 'config/constants/exchange'
import { BASE_S3 } from 'config'

export type ConfigMenuDropDownItemsType = DropdownMenuItems & { hideSubNav?: boolean }
export type ConfigMenuItemsType = Omit<MenuItemsType, 'items'> & { hideSubNav?: boolean; image?: string } & {
  items?: ConfigMenuDropDownItemsType[]
}

const addMenuItemSupported = (item, chainId) => {
  if (!chainId || !item.supportChainIds) {
    return item
  }
  if (item.supportChainIds?.includes(chainId)) {
    return item
  }
  return {
    ...item,
    disabled: true,
  }
}

// 修改点：logo图片

const switchLogoIcon = () => {
  if (LogoSuffix[SubSwapName] === undefined) {
    return `${BASE_S3}/images/logo.png`
  }
  return `${BASE_S3}/images/main/logoicon-${LogoSuffix[SubSwapName]}.png`
}

const switchLogoText = () => {
  if (LogoSuffix[SubSwapName] === undefined) {
    return `${BASE_S3}/images/logo-with-text.png`
  }
  return `${BASE_S3}/images/main/logo-${LogoSuffix[SubSwapName]}.png`
}

export const logoConfig = {
  logoSrc: switchLogoIcon(),
  logoWithTextSrc: switchLogoText(),
}

const config: (
  t: ContextApi['t'],
  isDark: boolean,
  languageCode?: string,
  chainId?: number,
) => ConfigMenuItemsType[] = (t, isDark, languageCode, chainId) =>
  [
    {
      label: t('Swap'),
      icon: SwapIcon,
      fillIcon: SwapFillIcon,
      href: '/swap',
      showItemsOnMobile: false,
      items: [].map((item) => addMenuItemSupported(item, chainId)),
    },

    {
      label: t('Bridge'),
      href: 'https://www.okx.com/zh-hans/xlayer/bridge',
      icon: BackForwardIcon,
      fillIcon: BackForwardIcon,
      showItemsOnMobile: false,
      items: [].map((item) => addMenuItemSupported(item, chainId)),
    },
    {
      label: t('Liquidity'),
      href: '/liquidity',
      icon: EarnIcon,
      fillIcon: EarnFillIcon,
      showItemsOnMobile: false,
      image: 'https://dql4d4zhzt5hs.cloudfront.net/images/decorations/pe2.png',
      items: [].map((item) => addMenuItemSupported(item, chainId)),
    },

    // {
    //   label: t('AITRADINGS'),
    //   href: `https://aitradings.momorobot.org/`,
    //   icon: NftIcon,
    //   fillIcon: NftFillIcon,
    //   showItemsOnMobile: false,

    //   items: [],
    // },
    // {
    //   label: '',
    //   href: '',
    //   icon: MoreIcon,
    //   hideSubNav: true,
    //   items: [
    //     {
    //       label: t('LOCK'),
    //       href: 'https://www.maplesales.org/mapLock/create',
    //       type: DropdownMenuItemType.EXTERNAL_LINK,
    //     },
    //     {
    //       label: t('AITRADINGS'),
    //       href: 'https://aitradings.momorobot.org/',
    //       type: DropdownMenuItemType.EXTERNAL_LINK,
    //     },
    //     {
    //       type: DropdownMenuItemType.DIVIDER,
    //     },
    //   ].map((item) => addMenuItemSupported(item, chainId)),
    // },
  ].map((item) => addMenuItemSupported(item, chainId))

export default config
