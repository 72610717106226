import { ChainId, JSBI, Percent, Token, WNATIVE, SubSwapType, SubSwapName } from '@pancakeswap/sdk'
import { BigNumber } from '@ethersproject/bignumber'
import {
  bscTokens,
  bscTestnetTokens,
  USDC,
  USDT,
  BUSD,
  WBTC_ETH,
  telegramXTokens,
  USDB,
  bitTokens,
} from '@pancakeswap/tokens'

import { ChainMap, ChainTokenList } from './types'

// Swap名称，默认为BitSwap
export const SwapName = {
  [SubSwapType.MAIN]: 'OkMeme Swap',
  [SubSwapType.ABS]: 'OkMeme Swap',
}

// logo图片的后缀，默认为空
export const LogoSuffix = {
  [SubSwapType.MAIN]: 'main',
}

// exchange.ts
// 修改点：Router地址

// 配置路由地址

const X_LAYER_ROUTER = {
  [SubSwapType.MAIN]: '0x4caf8Ab913b076cd33114397CAAa08A92Ac6671b',
  [SubSwapType.ABS]: '0x64d02AEff06444C16Cf145E5062870bFaAB811FF',
}

const X_LAYER_DOMAIN_NAME = {
  [SubSwapType.MAIN]: 'OKMeme LPs',
  [SubSwapType.ABS]: 'Potato LPs',
}

const X_LAYER_LP_SYMBOL = {
  [SubSwapType.MAIN]: 'OKMeme-LP',
  [SubSwapType.ABS]: 'Pot-LP',
}

// btn10086.bitxswap.biz

export const ROUTER_ADDRESS: ChainMap<string> = {
  [ChainId.BIT]: '0xA15365c3ce84b2cc9D4D5C612c202354e4Cc2c1F',
  [ChainId.ETHEREUM]: '0x73adBBe305f63F789aBdb500Fd586404339B559A',
  [ChainId.RINKEBY]: '0xEfF92A263d31888d860bD50809A8D171709b7b1c',
  [ChainId.BASE]: '0x0F8790d79Ea6f1752feeB36f01C5607a9f1f92D2',
  [ChainId.X_LAYER]: X_LAYER_ROUTER[SubSwapName],
  [ChainId.GOERLI_BASE]: '0xDB240d69F323b6Bc3f4BA73659ae22A64964902b',
  [ChainId.GOERLI]: '0xd149f21Fe15f4e1cc02F88433832a0295B1453e6',
  [ChainId.BSC]: '0x5cCEB0aFbAed843896257E793147A11b279F0032',
  [ChainId.BSC_TESTNET]: '0x4F85FD25B221f3db27061799E9fEfFdFA2F854d5',
  [ChainId.TELEGRAM_X]: '0xAc9857519069b05eC338e486FcD7bfCc79B68D5a',
  // [ChainId.TELEGRAM_X]: '0x572a8Dd28Df02182B97deac1d922b3EAC349b7f7',
}

export const NEW_ROUTER_ADDRESS: ChainMap<string> = {
  [ChainId.BIT]: '0xA15365c3ce84b2cc9D4D5C612c202354e4Cc2c1F',
  [ChainId.ETHEREUM]: '0x73adBBe305f63F789aBdb500Fd586404339B559A',
  [ChainId.RINKEBY]: '0xEfF92A263d31888d860bD50809A8D171709b7b1c',
  [ChainId.BASE]: '0x0F8790d79Ea6f1752feeB36f01C5607a9f1f92D2',
  [ChainId.X_LAYER]: X_LAYER_ROUTER[SubSwapName],
  [ChainId.GOERLI_BASE]: '0xDB240d69F323b6Bc3f4BA73659ae22A64964902b',
  [ChainId.GOERLI]: '0xd149f21Fe15f4e1cc02F88433832a0295B1453e6',
  [ChainId.BSC]: '0x5cCEB0aFbAed843896257E793147A11b279F0032',
  [ChainId.BSC_TESTNET]: '0x4F85FD25B221f3db27061799E9fEfFdFA2F854d5',
  [ChainId.TELEGRAM_X]: '0xAc9857519069b05eC338e486FcD7bfCc79B68D5a',
  // [ChainId.TELEGRAM_X]: '0xb2d6Af4587ce2d0E65ed48377D14f85Ba72dD503',
}

export const DOMAIN_NAME: ChainMap<string> = {
  [ChainId.BIT]: 'BTC LPs',
  [ChainId.ETHEREUM]: 'Uniswap V2',
  [ChainId.RINKEBY]: 'Pancake LPs',
  [ChainId.GOERLI_BASE]: 'Base LPs',
  [ChainId.BASE]: 'Base LPs',
  [ChainId.GOERLI]: 'Uniswap V2',
  [ChainId.BSC]: 'Pancake LPs',
  [ChainId.BSC_TESTNET]: 'Pancake LPs',
  [ChainId.TELEGRAM_X]: 'TXMaple LPs',
  [ChainId.X_LAYER]: X_LAYER_DOMAIN_NAME[SubSwapName],
}

export const LP_SYMBOL: ChainMap<string> = {
  [ChainId.BIT]: 'WBTC-LP',
  [ChainId.ETHEREUM]: 'UNI-V2',
  [ChainId.RINKEBY]: 'Cake-LP',
  [ChainId.GOERLI_BASE]: 'Base-LP',
  [ChainId.BASE]: 'Base-LP',
  [ChainId.GOERLI]: 'UNI-V2',
  [ChainId.BSC]: 'Cake-LP',
  [ChainId.BSC_TESTNET]: 'Cake-LP',
  [ChainId.TELEGRAM_X]: 'TXMaple-LP',
  [ChainId.X_LAYER]: X_LAYER_LP_SYMBOL[SubSwapName],
}

export const CHAIN_TYPE: ChainMap<string> = {
  [ChainId.BIT]: 'bit',
  [ChainId.ETHEREUM]: 'eth',
  [ChainId.RINKEBY]: 'rinkeby',
  [ChainId.GOERLI_BASE]: 'base',
  [ChainId.BASE]: 'goerli_base',
  [ChainId.GOERLI]: 'goerli',
  [ChainId.BSC]: 'bsc',
  [ChainId.BSC_TESTNET]: 'bsc_testnet',
  // [ChainId.TELEGRAM_X]: '0x572a8Dd28Df02182B97deac1d922b3EAC349b7f7',
  // [ChainId.TELEGRAM_X]: '0x0B0B21a09eB235c04cD21b138bCc0A7ff83c5ac6',
  [ChainId.TELEGRAM_X]: 'tx',
  [ChainId.X_LAYER]: 'x_layer',
}

// 金刚区？？？
// used to construct intermediary pairs for trading
export const BASES_TO_CHECK_TRADES_AGAINST: ChainTokenList = {
  [ChainId.BIT]: [USDB[ChainId.BIT], bitTokens.bitx, WNATIVE[ChainId.BIT]],
  [ChainId.ETHEREUM]: [WNATIVE[ChainId.ETHEREUM], USDT[ChainId.ETHEREUM], USDC[ChainId.ETHEREUM], WBTC_ETH],
  [ChainId.RINKEBY]: [WNATIVE[ChainId.RINKEBY], USDC[ChainId.RINKEBY], BUSD[ChainId.RINKEBY]],
  [ChainId.GOERLI]: [WNATIVE[ChainId.GOERLI], USDC[ChainId.GOERLI], BUSD[ChainId.GOERLI]],
  [ChainId.GOERLI_BASE]: [WNATIVE[ChainId.GOERLI_BASE]],
  [ChainId.BASE]: [WNATIVE[ChainId.BASE], USDT[ChainId.BASE]],
  [ChainId.X_LAYER]: [WNATIVE[ChainId.X_LAYER], USDT[ChainId.X_LAYER]],
  [ChainId.BSC]: [bscTokens.wbnb, bscTokens.usdt, bscTokens.busd, bscTokens.btcb, bscTokens.eth, bscTokens.usdc],
  [ChainId.BSC_TESTNET]: [bscTestnetTokens.wbnb, bscTestnetTokens.cake, bscTestnetTokens.busd],
  [ChainId.TELEGRAM_X]: [telegramXTokens.wtx, telegramXTokens.ustx, telegramXTokens.usdt],
}

/**
 * Additional bases for specific tokens
 * @example { [WBTC.address]: [renBTC], [renBTC.address]: [WBTC] }
 */
// 修改点： 自定义路由，注意要双向
export const ADDITIONAL_BASES: { [chainId in ChainId]?: { [tokenAddress: string]: Token[] } } = {
  [ChainId.BSC]: {
    // SNFTS-SFUND
    [bscTokens.snfts.address]: [bscTokens.sfund],
  },
  [ChainId.BIT]: {
    [bitTokens.bitx.address]: [WNATIVE[ChainId.BIT]],
    [bitTokens.bitx.address]: [bitTokens.brc],
    [WNATIVE[ChainId.BIT].address]: [bitTokens.bitx],
    [bitTokens.brc.address]: [bitTokens.bitx],
    [bitTokens.usdb.address]: [bitTokens.bitx],
    [bitTokens.bitx.address]: [bitTokens.usdb],
  },
}

/**
 * Some tokens can only be swapped via certain pairs, so we override the list of bases that are considered for these
 * tokens.
 * @example [AMPL.address]: [DAI, WNATIVE[ChainId.BSC]]
 */
// 只能用此币对交易，注意双向
export const CUSTOM_BASES: { [chainId in ChainId]?: { [tokenAddress: string]: Token[] } } = {
  [ChainId.BSC]: {},
  // [ChainId.BIT]: {[bitTokens.bitx.address]: [bitTokens.brc,WNATIVE[ChainId.BIT]]},
}

// 金刚区
// used for display in the default list when adding liquidity
export const SUGGESTED_BASES: ChainTokenList = {
  [ChainId.BIT]: [USDB[ChainId.BIT], bitTokens.bitx, WNATIVE[ChainId.BIT]],
  [ChainId.ETHEREUM]: [USDT[ChainId.ETHEREUM], WNATIVE[ChainId.ETHEREUM], WBTC_ETH, USDC[ChainId.ETHEREUM]],
  [ChainId.RINKEBY]: [USDC[ChainId.RINKEBY], WNATIVE[ChainId.RINKEBY], BUSD[ChainId.RINKEBY]],
  [ChainId.GOERLI]: [USDC[ChainId.GOERLI], WNATIVE[ChainId.GOERLI], BUSD[ChainId.GOERLI]],
  [ChainId.GOERLI_BASE]: [WNATIVE[ChainId.GOERLI_BASE]],
  [ChainId.X_LAYER]: [WNATIVE[ChainId.X_LAYER], USDT[ChainId.X_LAYER]],
  [ChainId.BASE]: [WNATIVE[ChainId.BASE], USDT[ChainId.BASE]],
  [ChainId.BSC]: [bscTokens.usdt, bscTokens.busd, bscTokens.btcb],
  [ChainId.BSC_TESTNET]: [bscTestnetTokens.wbnb, bscTestnetTokens.busd],
  [ChainId.TELEGRAM_X]: [],
}

// used to construct the list of all pairs we consider by default in the frontend
export const BASES_TO_TRACK_LIQUIDITY_FOR: ChainTokenList = {
  [ChainId.BIT]: [WNATIVE[ChainId.BIT], USDB[ChainId.BIT]],
  [ChainId.ETHEREUM]: [USDC[ChainId.ETHEREUM], WNATIVE[ChainId.ETHEREUM], USDT[ChainId.ETHEREUM], WBTC_ETH],
  [ChainId.RINKEBY]: [USDC[ChainId.RINKEBY], WNATIVE[ChainId.RINKEBY], BUSD[ChainId.RINKEBY]],
  [ChainId.GOERLI]: [USDC[ChainId.GOERLI], WNATIVE[ChainId.GOERLI], BUSD[ChainId.GOERLI]],
  [ChainId.GOERLI_BASE]: [WNATIVE[ChainId.GOERLI_BASE]],
  [ChainId.BASE]: [WNATIVE[ChainId.BASE], USDT[ChainId.BASE]],
  [ChainId.X_LAYER]: [WNATIVE[ChainId.X_LAYER], USDT[ChainId.X_LAYER]],
  [ChainId.BSC]: [bscTokens.wbnb, bscTokens.dai, bscTokens.busd, bscTokens.usdt],
  [ChainId.BSC_TESTNET]: [bscTestnetTokens.wbnb, bscTestnetTokens.busd],
  [ChainId.TELEGRAM_X]: [telegramXTokens.wtx, telegramXTokens.ustx, telegramXTokens.usdt],
}

export const PINNED_PAIRS: { readonly [chainId in ChainId]?: [Token, Token][] } = {
  [ChainId.ETHEREUM]: [
    [WNATIVE[ChainId.ETHEREUM], USDC[ChainId.ETHEREUM]],
    [WBTC_ETH, WNATIVE[ChainId.ETHEREUM]],
    [WNATIVE[ChainId.ETHEREUM], USDT[ChainId.ETHEREUM]],
  ],
  [ChainId.BSC]: [
    [bscTokens.cake, bscTokens.wbnb],
    [bscTokens.busd, bscTokens.usdt],
    [bscTokens.dai, bscTokens.usdt],
  ],
  [ChainId.TELEGRAM_X]: [[telegramXTokens.wtx, telegramXTokens.usdt]],
  // [ChainId.BASE]: [[WNATIVE[ChainId.BASE], USDT[ChainId.BASE]]],
}

export const BIG_INT_ZERO = JSBI.BigInt(0)
export const BIG_INT_TEN = JSBI.BigInt(10)

// one basis point
export const BIPS_BASE = JSBI.BigInt(10000)
export const ONE_BIPS = new Percent(JSBI.BigInt(1), BIPS_BASE)
// used for warning states
export const ALLOWED_PRICE_IMPACT_LOW: Percent = new Percent(JSBI.BigInt(100), BIPS_BASE) // 1%
export const ALLOWED_PRICE_IMPACT_MEDIUM: Percent = new Percent(JSBI.BigInt(300), BIPS_BASE) // 3%
export const ALLOWED_PRICE_IMPACT_HIGH: Percent = new Percent(JSBI.BigInt(500), BIPS_BASE) // 5%
// if the price slippage exceeds this number, force the user to type 'confirm' to execute
export const PRICE_IMPACT_WITHOUT_FEE_CONFIRM_MIN: Percent = new Percent(JSBI.BigInt(6000), BIPS_BASE) // 原始10%，现在改为60%，以下不需要输入confirm
// for non expert mode disable swaps above this
export const BLOCKED_PRICE_IMPACT_NON_EXPERT: Percent = new Percent(JSBI.BigInt(1500), BIPS_BASE) // 15%

// used to ensure the user doesn't send so much BNB so they end up with <.01
export const MIN_BNB: JSBI = JSBI.exponentiate(BIG_INT_TEN, JSBI.BigInt(16)) // .01 BNB
export const BETTER_TRADE_LESS_HOPS_THRESHOLD = new Percent(JSBI.BigInt(50), BIPS_BASE)

export const ZERO_PERCENT = new Percent('0')
export const ONE_HUNDRED_PERCENT = new Percent('1')

// 基础费率，原始千分之25，现在千分之105
export const BASE_FEE = new Percent(JSBI.BigInt(25), BIPS_BASE)
export const INPUT_FRACTION_AFTER_FEE = ONE_HUNDRED_PERCENT.subtract(BASE_FEE)

// BNB
export const DEFAULT_INPUT_CURRENCY = 'BNB'
// CAKE 默认代币合约
export const DEFAULT_OUTPUT_CURRENCY = ''

// Handler string is passed to Gelato to use PCS router
export const GELATO_HANDLER = 'pancakeswap'
export const GENERIC_GAS_LIMIT_ORDER_EXECUTION = BigNumber.from(500000)

export const LIMIT_ORDERS_DOCS_URL = 'https://docs.swap.bitchain.biz/products/pancakeswap-exchange/limit-orders'

export const EXCHANGE_PAGE_PATHS = ['/swap', '/limit-orders', 'liquidity', '/add', '/find', '/remove']
